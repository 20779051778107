import TopAppBar from "./TopAppBar";
import {
  Box,
  Button, Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import useGetMe from "../hooks/queries/useGetMe";
import useGetTimeZones from "../hooks/queries/useGetTimeZones";
import LoadingPage from "./LoadingPage";
import {useEffect, useState} from "react";
import useSaveSettings from "../hooks/mutations/useSaveSettings";
import {useQueryClient} from "react-query";
import useChangeConsent from "../hooks/mutations/useChangeConsent";
import ErrorContent from "./ErrorContent";
import useSendEnrollment from "../hooks/mutations/useSendEnrollment";
import AlertSnackbar from "./AlertSnackbar";
import {useNavigate} from "react-router-dom";

export default function Settings() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {data: me, isLoading: meLoading, isError: meError} = useGetMe();
  const {data: timeZones} = useGetTimeZones();
  const [isError, setIsError] = useState<boolean>();
  const [message, setMessage] = useState('');
  const compMethods = ['SMS', 'Web Interface'];

  const saveMutation = useSaveSettings({
    onSuccess: () => {
      queryClient.invalidateQueries('me');
      setIsError(false);
      setMessage('Successfully saved reminder settings');
    },
    onError: () => {
      setIsError(true);
      setMessage('Unable to save reminder settings');
    }
  });

  const optMutation = useChangeConsent({
    onSuccess: () => {
      queryClient.invalidateQueries('me');
      setIsError(false);
      setMessage('Successfully saved consent');
    },
    onError: () => {
      setIsError(true);
      setMessage('Unable to save consent');
    }
  });

  const enrollmentMutation = useSendEnrollment({
    onSuccess: () => {
      queryClient.invalidateQueries('me');
      setIsError(false);
      setMessage('Successfully enrolled!');
      navigate('/reminders');
    },
    onError: () => {
      setIsError(true);
      setMessage('Unable to complete enrollment');
    }
  });

  const enrolling = me && me.enrollState === "SNT_OPT_IN";
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [timeZone, setTimeZone] = useState('');
  const [legalAgree, setLegalAgree] = useState(false);
  const [linkComplete, setCompMethod] = useState('');

  useEffect(() => {
    if (me) {
      let displayName = me.firstName;
      if (me.preferredName && me.preferredName !== 'UNKNOWN') {
        displayName = me.preferredName;
      }
      setName(displayName);
      setEmail(me.email || "")
      setTimeZone(me.timeZone || "")
    }
  }, [me]);

  if (meLoading) {
    return <LoadingPage/>;
  }

  if (meError) {
    return (
      <>
        <TopAppBar title="Settings"/>
        <ErrorContent />
      </>
    );
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginLeft: '10px',
      marginRight: '10px'
    }}>
      <TopAppBar title={`${me && me.sponsor ? `${me.sponsor.name} ` : ""}${enrolling ? 'Enrollment' : 'Settings'}`} />
      {me.enrollState === "OPTED_IN" && (
        <>
          <Typography variant="h6">
            {me.preferredName === 'UNKNOWN' ? me.firstName : me.preferredName}
          </Typography>
          <TextField
            fullWidth
            sx={{marginBottom: "10px", marginTop: '10px'}}
            id="name"
            value={name}
            label="Edit Preferred Name"
            onChange={(evt) => setName(evt.target.value)}
          />
          <TextField
            fullWidth
            sx={{marginBottom: "10px"}}
            id="email"
            value={email}
            label="Edit Email"
            onChange={(evt) => setEmail(evt.target.value)}
          />
          <FormControl>
            <InputLabel id="tz-label">Edit Time Zone</InputLabel>
            <Select
              sx={{marginBottom: "10px"}}
              labelId="tz-select-label"
              id="tz-select"
              value={timeZone}
              label="Time Zone"
              onChange={(evt) => setTimeZone(evt.target.value)}
            >
              {
                timeZones ? timeZones.map((t: any) => (
                  <MenuItem value={t.id} key={t.id}>{t.name}</MenuItem>
                )) : <MenuItem disabled>None</MenuItem>
              }
            </Select>
          </FormControl>
          {/* <FormControl>
            <InputLabel id="comp-label">Choose Reminder Viewing</InputLabel>
            <Select
              labelId="comp-select-label"
              id="comp-select"
              value={linkComplete}
              label="Completion Method"
              onChange={(evt) => setCompMethod(evt.target.value)}
            >
                {
                compMethods ? compMethods.map((t: any) => (
                  <MenuItem value={t} key={t}>{t}</MenuItem>
                )) : <MenuItem disabled>None</MenuItem>
              }
            </Select>
          </FormControl> */}
          <Button
            fullWidth
            sx={{marginTop: '10px'}}
            variant="contained"
            disabled={me.preferredName === name && me.timeZone === timeZone && me.email === email}
            onClick={() => {
              saveMutation.mutate({
                ...me,
                name,
                timeZone,
                email,
              })
            }}
          >
            Save
          </Button>
          <Typography variant="body1" sx={{marginBottom: "10px", marginTop: '10px', textAlign: 'center'}}>
            You are currently "Opted In". This means you have consented to receive reminder messages from {me.sponsor.name}.
            Click the "Opt Out" button below if you no longer wish to receive reminder messages.
          </Typography>
          <Button
            sx={{marginTop: '10px'}}
            variant="contained"
            onClick={() => {
              optMutation.mutate({optIn: false})
            }}
          >
            Opt Out
          </Button>
        </>
      )}
      {me.enrollState === "OPTED_OUT" && (
        <>
          <Typography variant="body1" sx={{marginBottom: "10px", textAlign: 'center'}}>
            You are currently "Opted Out". This means you will not receive reminder messages from {me.sponsor.name}.
            Click the "Opt In" button below to give your consent to receive reminder messages.
          </Typography>
          <Button
            fullWidth
            sx={{marginTop: '10px'}}
            variant="contained"
            onClick={() => {
              optMutation.mutate({optIn: true})
            }}
          >
            Opt In
          </Button>
        </>
      )}
      {me.enrollState === "SNT_OPT_IN" && (
        <>
          {name && (
            <Typography variant="h6" sx={{marginLeft: "5px"}}>
              Welcome {name}, we're happy you're here
            </Typography>
          )}
          <TextField
            fullWidth
            sx={{marginBottom: "10px", marginTop: '15px'}}
            id="name"
            value={name}
            label="Preferred Name"
            onChange={(evt) => setName(evt.target.value)}
          />
          <FormControl sx={{marginBottom: '10px'}}>
            <InputLabel id="tz-label-enroll">Time Zone</InputLabel>
            <Select
              required
              labelId="tz-select-label"
              id="tz-select"
              value={timeZone}
              label="Time Zone"
              onChange={(evt) => setTimeZone(evt.target.value)}
            >
              {
                timeZones ? timeZones.map((t: any) => (
                  <MenuItem value={t.id} key={t.id}>{t.name}</MenuItem>
                )) : <MenuItem disabled>None</MenuItem>
              }
            </Select>
          </FormControl>
          {/* <FormControl>
            <InputLabel id="comp-label">Choose Reminder Viewing</InputLabel>
            <Select
              labelId="comp-select-label"
              id="comp-select"
              value={linkComplete}
              label="Completion Method"
              onChange={(evt) => setCompMethod(evt.target.value)}
            >
                {
                compMethods ? compMethods.map((t: any) => (
                  <MenuItem value={t} key={t}>{t}</MenuItem>
                )) : <MenuItem disabled>None</MenuItem>
              }
            </Select>
          </FormControl> */}
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  required
                  checked={legalAgree}
                  onChange={(evt) => setLegalAgree(evt.target.checked)}
                />
              }
              label="I agree to the Terms of Service and Privacy Policy"
            />
          </FormGroup>
          <Button
            fullWidth
            sx={{marginTop: '10px'}}
            variant="contained"
            disabled={name === "" || timeZone === "" || !legalAgree}
            onClick={() => {

              let linkCompleteBool = false;
              if(linkComplete === 'Link'){
                linkCompleteBool = true;
              }

              enrollmentMutation.mutate({
                name: name,
                timeZone: timeZone,
                legalAgree: legalAgree,
                linkToComplete: linkCompleteBool
              })
            }}
          >
            Complete Enrollment
          </Button>

          <Button
            href="https://app.termly.io/document/terms-of-use-for-website/7f38a700-a782-46b1-8450-fd236c656647"
            target="rxm_tos"
            sx={{marginTop: '10px'}}
          >
            View Terms of Service
          </Button>

          <Button
            href="https://app.termly.io/document/privacy-policy/950ad8ba-f805-4c71-a456-8bf37d9f3ed9"
            target="rxm_pri"
            sx={{marginTop: '10px'}}
          >
            View Privacy Policy
          </Button>
        </>
      )}
      <AlertSnackbar message={message} isError={isError} onClose={() => setIsError(undefined)} />
    </Box>
  )
}
