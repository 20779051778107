import {useMutation, UseMutationOptions} from "react-query";

type CreateRequest = {
  req: {id: number, type: string, name: string, text: string}
  file: File | undefined
  s3File: string | undefined;
}

export default function useCreateContent(
  options: UseMutationOptions<any, any, any>
) {
  return useMutation<any, any, any>(
    async (c: CreateRequest) => {
      const hasFile = c.file !== undefined;
      const hasS3 = c.s3File !== undefined;

      let req;
      if (hasFile) {
        req = {...c.req, filename: c.file?.name};
      } else if (hasS3) {
        req = {...c.req, filename: c.s3File};
      } else {
        req = c.req;
      }

      if (hasFile && !hasS3) {
        const formData = new FormData();
        formData.append('file-upload', c.file!, c.file!.name);

        let url = ''
        await fetch(`/api/presigned_url/put/${c.file!.name}`, {
          method: 'GET',
          credentials: 'include',
          // @ts-ignore
          headers: new Headers({'Content-Type': 'application/json'}),
        }).then((response) => response.json()).then((data) => {
          url = data.url;
        });

        const s3 = await fetch(url, {
          method: 'PUT',
          // @ts-ignore
          headers: new Headers({'Content-Type': [c.file!.type]}),
          body: c.file
        });

        if (s3.status !== 200) {
          throw new Error("unable to put file");
        }
      }

      // @ts-ignore
      await fetch('/api/content', {
        method: 'POST',
        credentials: 'include',
        headers: new Headers({'Content-Type' : 'application/json'}),
        body: JSON.stringify(req),
      });
    },
    options
  );
}