import React from "react";
import TopAppBar from "./TopAppBar";
import {
  Box,
  Button, Checkbox, Collapse,
  Dialog,
  DialogActions,
  DialogTitle, Divider,
  FormControl, FormControlLabel, FormGroup, InputLabel,
  List,
  ListItem,
  ListItemButton, ListItemIcon,
  ListItemText, MenuItem, Radio, RadioGroup, Select,
  TextField,
  Typography
} from "@mui/material";
import useGetReminders from "../hooks/queries/useGetReminders";
import useGetTimeSlots from "../hooks/queries/useGetTimeSlots";
import useGetContentTypes from "../hooks/queries/useGetContentTypes";
import {useEffect, useState} from "react";
import useSaveReminder from "../hooks/mutations/useSaveReminder";
import useDeleteReminder from "../hooks/mutations/useDeleteReminder";
import {QueryClient, useQueryClient} from "react-query";
import LoadingPage from "./LoadingPage";
import AlertSnackbar from "./AlertSnackbar";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorContent from "./ErrorContent";
import useGetMe from "../hooks/queries/useGetMe";
import {LocalizationProvider, MobileTimePicker, TimePicker} from "@mui/x-date-pickers";
import dayjs, {Dayjs} from 'dayjs';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {HelpTooltip} from "./common/HelpTooltip";
import useIsMobile from "../mui/useIsMobile";
import { useSearchParams } from "react-router-dom";

export default function Reminders() {
  const queryClient = useQueryClient();
  const {isError: meError} = useGetMe();
  const {data: me, isLoading: rLoading} = useGetReminders();
  const {data: timeSlots, isLoading: tLoading} = useGetTimeSlots();
  const {data: contentType, isLoading: cLoading} = useGetContentTypes(me ? me.sponsor.id : undefined, false);
  const [isError, setIsError] = useState<boolean>();
  const [message, setMessage] = useState('');
  const [reminders, setReminders] = useState<any[]>([]);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (me) {
      setReminders(me.reminders)
    }
  }, [me]);

  if (rLoading || tLoading || cLoading) {
    return <LoadingPage/>
  }

  if (meError) {
    return (
      <>
        <TopAppBar title="Reminders"/>
        <ErrorContent/>
      </>
    );
  }


  // @ts-ignore
  if (!!searchParams.get('outseta')) {
    // @ts-ignore
    Outseta.on('accessToken.set', function (user) {
      // @ts-ignore
      window.location = "/r/account/" + user.sub;
    });
    // @ts-ignore
    const accessToken = Outseta.getAccessToken();
    if (!accessToken) {
      // @ts-ignore
      Outseta.auth.open(o_options);
    }
    // Outseta.setAccessToken(null); //This a one way to sign out, it seems
    return <LoadingPage />
  }

  const onComplete = (msg: string, err: boolean) => {
    setMessage(msg);
    setIsError(err);
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginLeft: '10px',
      marginRight: '10px'
    }}>
      <TopAppBar title={`${me && me.sponsor ? `${me.sponsor.name} ` : ""}Reminders`}/>
      <Typography variant="h6">
        {me.preferredName !== 'UNKNOWN' ? me.preferredName : me.firstName}
      </Typography>
      <List>
        {reminders.map((r: any) => (
          <Reminder
            r={r}
            queryClient={queryClient}
            contentType={contentType}
            timeSlots={timeSlots}
            onReminderAction={() => setReminders(me.reminders)}
            onCompleted={onComplete}
          />
        ))}
      </List>
      <Button
        fullWidth
        variant="contained"
        sx={{marginTop: '10px'}}
        disabled={reminders.length >= 4 || (reminders.length > 0 && reminders[reminders.length - 1].id === "new")}
        onClick={() => {
          const selection = {
            id: "new",
            name: "",
            type: "",
            timeSlot: "",
            useImage: true,
            days: "all",
          };

          setReminders([...reminders, selection]);
        }}
      >
        Add new
      </Button>

      <Button
        href="/r/logout/logout#o-logout-link">
          Logout
      </Button>
      <AlertSnackbar message={message} isError={isError} onClose={() => setIsError(undefined)}/>
    </Box>
  )
}

interface ReminderProps {
  r: any;
  queryClient: QueryClient;
  contentType: any;
  timeSlots: any;
  onReminderAction: () => void
  onCompleted: (msg: string, err: boolean) => void;
}

function Reminder({r, queryClient, contentType, timeSlots, onReminderAction, onCompleted}: ReminderProps) {
  const [reminderName, setReminderName] = useState(r ? r.name : '');
  const [reminderType, setReminderType] = useState(r ? r.type : '');
  const [timeSlot, setTimeSlot] = useState(r ? r.timeSlot : '');
  const [open, setOpen] = useState(false);
  const [timeStrategy, setTimeStrategy] = useState(r ? r.timeStrategy : 'window');
  const [exactTime, setExactTime] = useState<Dayjs | null>(r ? dayjs(new Date(r.sendTime)) : dayjs(new Date()));
  const [deleting, setDeleting] = useState(false);
  const isMobile = useIsMobile();

  const onInitialize = (r: any, name: string) => {
    return r.days.split(",").some((a: string) => a === name);
  }
  const [resultDays, setResultDays] = useState<Map<string, boolean>>(new Map([
    ["Sunday", onInitialize(r, "Sunday")],
    ["Monday", onInitialize(r, "Monday")],
    ["Tuesday", onInitialize(r, "Tuesday")],
    ["Wednesday", onInitialize(r, "Wednesday")],
    ["Thursday", onInitialize(r, "Thursday")],
    ["Friday", onInitialize(r, "Friday")],
  ]));

  const deleteMutation = useDeleteReminder({
    onSuccess: () => {
      queryClient.invalidateQueries('reminders');
      setOpen(false);
      onCompleted('Deleted Reminders', false);
    },
    onError: () => {
      onCompleted('Unable to delete Reminders', true);
    }
  });

  const saveMutation = useSaveReminder({
    onSuccess: () => {
      queryClient.invalidateQueries('reminders');
      setOpen(false);
      onCompleted('Saved Reminders', false);
    },
    onError: () => {
      onCompleted('Unable to save Reminders', true);
    }
  });

  const onDateChange = (name: string, checked: boolean) => {
    let r: Map<string, boolean> = resultDays;
    if (name === "all") {
      if (checked) {
        r.set("Sunday", false);
        r.set("Monday", false);
        r.set("Tuesday", false);
        r.set("Wednesday", false);
        r.set("Thursday", false);
        r.set("Friday", false);
        r.set("Saturday", false);
        setResultDays(r);
      }
      return;
    }

    r.set(name, checked);
    setResultDays(r);
  }

  const daysResult = (r: Map<string, boolean>) => {
    const entries = Array.from(r.entries());
    const anyTrue = entries.some((a) => a[1]);

    if (anyTrue) {
      return entries.filter((a) => a[1]).map((a) => a[0]).join(",");
    } else {
      return "all"
    }
  }

  const getTime = (timeSlot: string, timeStrategy: string) => {
    const t = timeSlot.split(":");
    const date = new Date();
    date.setHours(Number(t[0]), Number(t[1]), 0, 0);

    if (timeStrategy === "window") {
      const thirty = new Date(date);
      thirty.setMinutes(thirty.getMinutes() + 30);
      const a = date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
      const b = thirty.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
      return `between ${a} and ${b}`;
    }

    const d = date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
    return `at ${d}`;
  }

  return (
    <>
      <ListItem sx={{display: "flex", alignItems: "center", flexDirection: "row"}}>
        <ListItemText disableTypography sx={{width: '100%'}}>
          <Typography variant="subtitle1">{r.name}</Typography>
          <Typography variant="caption">
            {`${r.id === 'new' ? "New Reminder" : `${r.days === "all" ? "Everyday" : `${r.days}`} ${getTime(r.timeSlot, r.timeStrategy)}`}`}
          </Typography>
          <ListItemIcon sx={{ml: .5}}>
            <HelpTooltip
              text={(
                <Typography>
                  If you set a reminder less than 30 minutes in the future, it will send the next day or the closest
                  selected day.
                </Typography>
              )}
            />
          </ListItemIcon>
        </ListItemText>

      </ListItem>
      <ListItemButton>
        <Button
          sx={{marginRight: "10px"}}
          variant="outlined"
          onClick={() => setOpen(!open)}
        >
          <EditIcon/>Edit
        </Button>
        <Button sx={{marginRight: "10px"}} variant="outlined" onClick={() => {
          if (r.id === "new") {
            onReminderAction();
          } else {
            setDeleting(true)
          }
        }}>
          <DeleteIcon/>Delete
        </Button>
        <Dialog
          open={deleting}
          onClose={() => setDeleting(false)}
        >
          <DialogTitle>
            <Typography sx={{
              fontWeight: "bold",
              textAlign: "center",
              flexDirection: "column"
            }}>{`Are you sure you want to delete reminder: ${r.name}`}</Typography>
          </DialogTitle>
          <DialogActions sx={{justifyContent: 'center'}}>
            <Button onClick={() => setDeleting(false)}>Cancel</Button>
            <Button
              onClick={() => {
                setDeleting(false);
                deleteMutation.mutate({...r});
              }}
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </ListItemButton>
      <Collapse in={open || r.id === "new"}>
        <Box sx={{padding: 1, border: 1, borderColor: '#3F4CA0', borderRadius: '16px', marginTop: '5px'}}>
          <TextField
            fullWidth
            sx={{marginBottom: "10px", marginTop: '10px'}}
            id="name"
            value={reminderName}
            label="Reminder Name"
            onChange={(evt) => setReminderName(evt.target.value)}
          />
          <FormControl fullWidth sx={{marginBottom: '10px'}}>
            <InputLabel id="reminder-label">Reminder Type</InputLabel>
            <Select
              labelId="reminder-select-label"
              id="reminder-type"
              value={reminderType}
              label="Reminder Type"
              onChange={(evt) => setReminderType(evt.target.value)}
            >
              {contentType ? contentType.map((c: any) => (
                <MenuItem value={c.id} key={c.id}>{c.name}</MenuItem>
              )) : <MenuItem value="">None</MenuItem>}
            </Select>
          </FormControl>
          <Divider/>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="subtitle1" sx={{ml: .5}}>Select your reminder time</Typography>
            <HelpTooltip text={(
              <Typography>
                Choose the time you want to receive your reminder.
                <br/> <br/>
                30 min window: Receive your reminder at any any point between the times you select. This can
                help reduce alert fatigue and promote better adherence.
                <br/> <br/>
                Specific time: Receive your reminder at the exact time you choose.
              </Typography>
            )}/>
          </Box>
          <FormControl>
            <RadioGroup
              row
              value={timeStrategy}
              onChange={(evt) => setTimeStrategy(evt.target.value)}
            >
              <FormControlLabel value="window" control={<Radio/>} label="30 min window"/>
              <FormControlLabel value="exact" control={<Radio/>} label="Specific time"/>
            </RadioGroup>
          </FormControl>
          {timeStrategy === "window" && (
            <FormControl fullWidth sx={{marginBottom: '10px'}}>
              <InputLabel id="reminder-label">Time to Send Reminder</InputLabel>
              <Select
                labelId="reminder-select-label"
                id="reminder-type"
                value={timeSlot}
                label="Time to Send Reminder"
                onChange={(evt) => setTimeSlot(evt.target.value)}
              >
                {timeSlots ? timeSlots.map((s: any) => (
                  <MenuItem value={s.id} key={s.id}>{s.name}</MenuItem>
                )) : <MenuItem value="">None</MenuItem>}
              </Select>
            </FormControl>
          )}
          {timeStrategy === "exact" && (
            <Box marginBottom="10px">
              <br/>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {isMobile ? (
                  <MobileTimePicker
                    label="Time to Send Reminder"
                    value={exactTime}
                    onChange={(evt: Dayjs | null) => {
                      if (evt) {
                        const slot = `${evt.hour()}:${evt.minute()}`;
                        console.log(slot);
                        setTimeSlot(slot);
                      }

                      setExactTime(evt);
                    }}
                    ampmInClock
                    renderInput={(params) => <TextField {...params} />}
                  />
                ) : (
                  <TimePicker
                    label="Time to Send Reminder"
                    value={exactTime}
                    onChange={(evt: Dayjs | null) => {
                      if (evt) {
                        const slot = `${evt.hour()}:${evt.minute()}`;
                        console.log(slot);
                        setTimeSlot(slot);
                      }

                      setExactTime(evt);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                )}
              </LocalizationProvider>
            </Box>
          )}
          <Divider/>
          <Days
            days={r.days}
            onDateChange={onDateChange}
          />
          <Button
            fullWidth
            variant="contained"
            disabled={reminderType === "" || reminderName === ""}
            onClick={() => {
              saveMutation.mutate({
                ...r,
                name: reminderName,
                type: reminderType,
                timeSlot: timeSlot,
                useImage: true,
                timeStrategy: timeStrategy,
                daysVal: daysResult(resultDays),
              });
            }}
          >
            Save
          </Button>
        </Box>
      </Collapse>
    </>
  )
}

interface DayProps {
  days: string;
  onDateChange: (name: string, checked: boolean) => void;
}

function Days({days, onDateChange}: DayProps) {
  const [everyday, setEveryday] = useState(days === "all");
  const hasInitialDay = (day: string, days: string) => {
    const split = days.split(",");
    return split.some((g) => g === day);
  }

  const [sunday, setSunday] = useState(hasInitialDay("Sunday", days));
  const [monday, setMonday] = useState(hasInitialDay("Monday", days));
  const [tuesday, setTuesday] = useState(hasInitialDay("Tuesday", days));
  const [wednesday, setWednesday] = useState(hasInitialDay("Wednesday", days));
  const [thursday, setThursday] = useState(hasInitialDay("Thursday", days));
  const [friday, setFriday] = useState(hasInitialDay("Friday", days));
  const [saturday, setSaturday] = useState(hasInitialDay("Saturday", days));

  const changeEveryDay = (checked: boolean) => {
    setEveryday(checked);
    onDateChange("all", checked);
    if (checked) {
      setSunday(false)
      setMonday(false)
      setTuesday(false)
      setWednesday(false)
      setThursday(false)
      setFriday(false)
      setSaturday(false)
    }
  }

  return (
    <FormGroup>
      <Typography variant="subtitle1">Select your days</Typography>
      <FormControlLabel
        control={<Checkbox checked={everyday} onChange={(evt) => {
          changeEveryDay(evt.target.checked);
        }}/>}
        label="Everyday"
        name="all"
      />
      <FormControlLabel
        control={<Checkbox checked={!everyday && sunday} onChange={(evt) => {
          setSunday(evt.target.checked)
          setEveryday(false);
          onDateChange(evt.target.name, evt.target.checked);
        }}/>}
        label="Sunday"
        name="Sunday"
      />
      <FormControlLabel
        control={<Checkbox checked={!everyday && monday} onChange={(evt) => {
          setMonday(evt.target.checked)
          setEveryday(false);
          onDateChange(evt.target.name, evt.target.checked);
        }}/>}
        label="Monday"
        name="Monday"
      />
      <FormControlLabel
        control={<Checkbox checked={!everyday && tuesday} onChange={(evt) => {
          setTuesday(evt.target.checked)
          setEveryday(false);
          onDateChange(evt.target.name, evt.target.checked);
        }}/>}
        label="Tuesday"
        name="Tuesday"
      />
      <FormControlLabel
        control={<Checkbox checked={!everyday && wednesday} onChange={(evt) => {
          setWednesday(evt.target.checked)
          setEveryday(false);
          onDateChange(evt.target.name, evt.target.checked);
        }}/>}
        label="Wednesday"
        name="Wednesday"
      />
      <FormControlLabel
        control={<Checkbox checked={!everyday && thursday} onChange={(evt) => {
          setThursday(evt.target.checked)
          setEveryday(false);
          onDateChange(evt.target.name, evt.target.checked);
        }}/>}
        label="Thursday"
        name="Thursday"
      />
      <FormControlLabel
        control={<Checkbox checked={!everyday && friday} onChange={(evt) => {
          setFriday(evt.target.checked)
          setEveryday(false);
          onDateChange(evt.target.name, evt.target.checked);
        }}/>}
        label="Friday"
        name="Friday"
      />
      <FormControlLabel
        control={<Checkbox checked={!everyday && saturday} onChange={(evt) => {
          setSaturday(evt.target.checked)
          setEveryday(false);
          onDateChange(evt.target.name, evt.target.checked);
        }}/>}
        label="Saturday"
        name="Saturday"
      />
    </FormGroup>
  )
}