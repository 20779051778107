import {Suspense} from 'react';
import './App.css';
import {QueryClient, QueryClientProvider} from 'react-query';
import theme from './theme';
import appRoutes from './appRoutes';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {ThemeProvider} from "@mui/material";
import LoadingPage from './components/LoadingPage';
import { RecoilRoot } from 'recoil';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 10_000
    }
  }
});

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <RecoilRoot>
            <Suspense fallback={
              <LoadingPage/>
            }>
              <Routes>
                {appRoutes.map(({...routeProps}) =>
                  <Route {...routeProps} key={routeProps.path as string}/>
                )}
              </Routes>
            </Suspense>
          </RecoilRoot>
        </QueryClientProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
