import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from "@mui/material";
import React from "react";
import useIsMobile from "../../mui/useIsMobile";

type InfoProps = {
  sponsor?: any;
}

export default function SignUpInfo({sponsor}: InfoProps) {
  const isMobile = useIsMobile();

  return (
    <Grid item xs={12}>
      <Card variant="outlined" sx={{backgroundColor: '#DBEFFA', borderRadius: '24px'}}>
        <CardContent sx={{display: 'flex', flexDirection: 'column'}}>
          {sponsor && sponsor.isPartner && (
            <Box sx={{alignSelf: 'center', display: 'flex', alignItems: 'center', marginBottom: '30px'}}>
              <Typography variant="caption" sx={{ marginRight: '5px' }}>
                In partnership with
              </Typography>
              <img src={`/${sponsor.id}.png`} alt={sponsor.name} height='25px' />
            </Box>
          )}
          <Typography sx={{fontSize: isMobile ? '20px' : '40px', color: '#583FBC', alignSelf: 'center', textAlign: 'center'}}>
            RxMinder sends you personal and flexible reminders {!isMobile && <br/>} that help you keep your wellness on track.
          </Typography>
          {!isMobile && (
            <Box display="flex" justifyContent="center">
              <video width="750" height="400" controls>
                <source src="/video.mp4" type="video/mp4" />
              </video>
            </Box>
          )}
          <Box sx={{display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-evenly'}}>
            <Box sx={{ alignItems: 'left' }}>
              <Typography sx={{fontSize: "24px", color: '#583FBC', marginTop: isMobile ? '20px' : '80px', fontWeight: 700, textAlign: isMobile ? 'center' : 'left'}}>
                HOW IT WORKS
              </Typography>
              <List>
                <ListItem alignItems="center" disableGutters sx={{flexDirection: isMobile ? 'column' : 'row'}}>
                  <ListItemAvatar>
                    <Avatar alt="One" src="/One.svg" />
                  </ListItemAvatar>
                  <ListItemText disableTypography>
                    <Typography sx={{fontSize: isMobile ? '18px' : '20px', textAlign: isMobile ? 'center' : 'left'}}>
                      <span style={{ color: '#583FBC', fontWeight: 700 }}>Set up</span> text reminders for a family member, a beloved pet, or yourself.
                    </Typography>
                  </ListItemText>
                </ListItem>
                <ListItem alignItems="center" disableGutters sx={{flexDirection: isMobile ? 'column' : 'row'}}>
                  <ListItemAvatar>
                    <Avatar alt="Two" src="/Two.svg" />
                  </ListItemAvatar>
                  <ListItemText disableTypography>
                    <Typography sx={{fontSize: isMobile ? '18px' : '20px', textAlign: isMobile ? 'center' : 'left'}}>
                      <span style={{ color: '#583FBC', fontWeight: 700 }}>Confirm</span> each reminder.
                    </Typography>
                  </ListItemText>
                </ListItem>
                <ListItem alignItems="center" disableGutters sx={{flexDirection: isMobile ? 'column' : 'row'}}>
                  <ListItemAvatar>
                    <Avatar alt="Three" src="/Three.svg" />
                  </ListItemAvatar>
                  <ListItemText disableTypography>
                    <Typography sx={{fontSize: isMobile ? '18px' : '20px', textAlign: isMobile ? 'center' : 'left'}}>
                      <span style={{ color: '#583FBC', fontWeight: 700 }}>Receive</span> a dose of entertainment such as a dad joke or positive affirmation.
                    </Typography>
                  </ListItemText>
                </ListItem>
              </List>
            </Box>
            {isMobile && (
              <Box display="flex" justifyContent="center">
                <video width="350" height="200" poster="/Penguin.svg" controls>
                  <source src="/video.mp4" type="video/mp4" />
                </video>
              </Box>
            )}
            <Box>
              <Typography sx={{fontSize: "24px", color: '#583FBC', marginTop: isMobile ? '20px' : '80px', fontWeight: 700, textAlign: isMobile ? 'center' : 'left'}}>
                WHY RxMINDER?
              </Typography>
              <List sx = {{ listStyleType: 'disc', pl: 2 }}>
                <ListItem alignItems="center" sx={{display: 'list-item'}} disableGutters={isMobile}>
                  <ListItemText primary="Any reminder you need from medications for chronic conditions to taking the dog for a walk."/>
                </ListItem>
                <ListItem alignItems="center" sx={{display: 'list-item'}} disableGutters={isMobile}>
                  <ListItemText primary="Provides peace of mind when taking care of a loved one, or yourself."/>
                </ListItem>
                <ListItem alignItems="center" sx={{display: 'list-item'}} disableGutters={isMobile}>
                  <ListItemText primary="Brightens your day with dad jokes or other sources of content that suits your personality."/>
                </ListItem>
                <ListItem alignItems="center" sx={{display: 'list-item'}} disableGutters={isMobile}>
                  <ListItemText primary="Delivers reminders through text messages so you don't have to download another app."/>
                </ListItem>
              </List>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
}