import Chart from 'react-apexcharts';
import React, {useEffect, useState} from 'react';
import {Box, FormControl, InputLabel, MenuItem, Select, TextField, Typography} from '@mui/material';
import useGetRemindersByInterval from '../../hooks/queries/useGetRemindersByInterval';
import LoadingPage from "../LoadingPage";
import useGetSponsors from "../../hooks/queries/useGetSponsors";

export default function RemindersByInterval() {
  const [chartData, setChartData] = useState<any>({options: {}, series: []});
  const [days, setDays] = useState<number>(7)
  const [sponsor, setSponsor] = useState('none');
  const {data: sponsors, isLoading: sponsorLoading} = useGetSponsors(null);
  const {data, isLoading, isError} = useGetRemindersByInterval(days, sponsor);

  useEffect(() => {
    if (data && data.reminders) {
      setChartData({
        options: {
          chart: {
            id: "reminders-by-week"
          },
          xaxis: {
            categories: data.reminders.map((elem: any) => elem.date)
          },
          title: {
            text: 'Reminders by day',
            align: 'center'
          }
        },
        series: [
          {
            name: "reminders-by-week",
            data: data.reminders.map((elem: any) => elem.data.count)
          }
        ]
      });
    }
  }, [data, sponsor])

  if (isLoading || sponsorLoading) {
    return (
      <Box sx={{minWidth: '600px'}}>
        <LoadingPage/>
      </Box>
    );
  }

  if (isError || chartData.series.length === 0) {
    return (
      <Typography variant="h4">
        No data available for reminders by week.
      </Typography>
    )
  }

  return (
    <Box sx={{minWidth: '600px'}}>
      <FormControl sx={{margin: '10px', minWidth: '150px'}}>
        <InputLabel id="sponsor-label">Sponsor Filter</InputLabel>
        <Select
          labelId="sponsor-select-label"
          id="sponsor-type"
          value={sponsor}
          label="Sponsor Filter"
          onChange={(evt) => {
            setSponsor(evt.target.value)
          }}
        >
          <MenuItem value="none">None</MenuItem>
          {
            sponsors ? sponsors.map((s: any) => (
              <MenuItem value={s.id} key={s.id}>{s.name}</MenuItem>
            )) : <MenuItem disabled>None</MenuItem>
          }
        </Select>
      </FormControl>
      <TextField
        sx={{margin: "10px"}}
        id="reminder-interval"
        value={days}
        label="Reminder day range"
        onChange={(evt) => setDays(parseInt(evt.target.value))}
        type="number"
      />
      <Chart
        type="bar"
        title="Reminders by day"
        series={chartData.series}
        options={chartData.options}
        height={360}
      />
    </Box>
  );
}