import {useQuery} from "react-query";

export default function useGetCustomerMetrics(sponsor: string, status: string) {
  const url = '/api/metrics/customers'

  return useQuery(`customers-${sponsor}-${status}`, async () => {
    // @ts-ignore
    const res = await fetch(`${url}?sponsor=${sponsor}&status=${status}`,{
      method: 'GET',
      credentials: 'include',
      headers: new Headers({'Content-Type' : 'application/json'}),
    });

    return res.json();
  }, {retry: false, refetchInterval: 60_000})
}