import TopAppBar from "./TopAppBar";
import {
  Box,
  Button, Checkbox,
  FormControl, FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import useGetSponsors from "../hooks/queries/useGetSponsors";
import React, {useEffect, useState} from "react";
import useGetContentTypes from "../hooks/queries/useGetContentTypes";
import LoadingPage from "./LoadingPage";
import useCreateReminders from "../hooks/mutations/useCreateReminders";
import AlertSnackbar from "./AlertSnackbar";

const runDays = [
  {nbr: "1", label: "Once"},
  {nbr: "2", label: "2 days"},
  {nbr: "3", label: "3 days"},
  {nbr: "-1", label: "Forever"},
]

export default function Watertower() {
  const {data: sponsors, isLoading: sponsorLoading} = useGetSponsors(null);
  const [sponsor, setSponsor] = useState('');
  const {data: contentType, isLoading: contentLoading} = useGetContentTypes(sponsor, false);
  const [isError, setIsError] = useState<boolean>();
  const [message, setMessage] = useState('');

  useEffect(() => {
    // @ts-ignore
    document.querySelector("link[rel~='icon']").href = "/water-tower.png"
  }, []);

  const [userPhone, setUserPhone] = useState('');
  const [confirmPhone, setConfirmPhone] = useState('');
  const [uniqueCode, setUniqueCode] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [reminderName, setReminderName] = useState('');
  const [activeStreak, setActiveStreak] = useState(0);
  const [interactions, setInteractions] = useState(0);
  const [reminderType, setReminderType] = useState('');
  const [sendReminder, setSendReminder] = useState(false);
  const [useImage, setUseImage] = useState(false);
  const [run, setRun] = useState('1');

  const mutation = useCreateReminders({
    onSuccess: () => {
      setIsError(false);
      setMessage( sendReminder ? "Successfully enrolled user and created reminder" : 'Successfully enrolled user')
      setSponsor('');
      setUserPhone('');
      setConfirmPhone('');
      setUniqueCode('');
      setFirstName('');
      setLastName('');
      setReminderName('');
      setActiveStreak(0);
      setInteractions(0);
      setReminderType('');
      setUseImage(false);
      setRun('1');
    },
    onError: () => {
      setIsError(true);
      setMessage("Unable to created reminder");
    }
  });

  if (sponsorLoading || contentLoading) {
    return <LoadingPage/>;
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginLeft: '10px',
      marginRight: '10px'
    }}>
      <TopAppBar title="Water Tower " showMenu={false}/>
      <FormControl sx={{marginBottom: '10px'}}>
        <InputLabel id="sponsor-label">Sponsor</InputLabel>
        <Select
          required
          labelId="sponsor-select-label"
          id="sponsor-select"
          value={sponsor}
          label="Sponsor"
          onChange={(evt) => setSponsor(evt.target.value)}
        >
          {
            sponsors ? sponsors.map((s: any) => (
              <MenuItem value={s.id} key={s.id}>{s.name}</MenuItem>
            )) : <MenuItem disabled>None</MenuItem>
          }
        </Select>
      </FormControl>
      <Box sx={{display: 'flex', flexDirection: 'row'}}>
        <TextField
          required
          fullWidth
          sx={{marginBottom: "10px", marginRight: '5px'}}
          id="first-name"
          value={firstName}
          label="User First Name"
          onChange={(evt) => setFirstName(evt.target.value)}
        />
        <TextField
          required
          fullWidth
          sx={{marginBottom: "10px", marginLeft: '5px'}}
          id="last-name"
          value={lastName}
          label="User Last Name"
          onChange={(evt) => setLastName(evt.target.value)}
        />
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'row'}}>
        <TextField
          required
          fullWidth
          sx={{marginBottom: "10px", marginRight: '5px'}}
          id="user-number"
          value={userPhone}
          label="User Phone #"
          onChange={(evt) => setUserPhone(evt.target.value)}
          type="phone"
        />
        <TextField
          required
          fullWidth
          sx={{marginBottom: "10px", marginLeft: '5px'}}
          id="confirm-phone"
          value={confirmPhone}
          label="Confirm Phone #"
          onChange={(evt) => setConfirmPhone(evt.target.value)}
          type="phone"
        />
      </Box>
      <TextField
        fullWidth
        sx={{marginBottom: "10px"}}
        id="unique-invitation"
        value={uniqueCode}
        label="Unique Invitation Code"
        onChange={(evt) => setUniqueCode(evt.target.value)}
      />
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox onChange={(evt) => {
              setSendReminder(evt.target.checked)
            }}/>
          }
          label="Send a reminder"
        />
      </FormGroup>
      {sendReminder && (
        <Box sx={{padding: 1, border: 1, borderColor: '#3F4CA0', borderRadius: '16px'}}>
          <TextField
            fullWidth
            sx={{marginBottom: "10px"}}
            id="reminder-name"
            value={reminderName}
            label="Reminder Name"
            onChange={(evt) => {
              setReminderName(evt.target.value);
              if (!evt.target.value) {
                setReminderType('');
                setRun('');
              }
            }}
          />
          <Box sx={{display: 'flex', flexDirection: 'row'}}>
            <TextField
              fullWidth
              sx={{marginBottom: "10px", marginRight: "5px"}}
              id="active-streak"
              value={activeStreak}
              label="Active Streak Days"
              onChange={(evt) => setActiveStreak(parseInt(evt.target.value))}
              type="number"
            />
            <TextField
              fullWidth
              sx={{marginBottom: "10px", marginLeft: "5px"}}
              id="total-interactions"
              value={interactions}
              label="Total Interactions"
              onChange={(evt) => setInteractions(parseInt(evt.target.value))}
              type="number"
            />
          </Box>
          <FormControl fullWidth>
            <InputLabel id="reminder-label">Reminder Type</InputLabel>
            <Select
              labelId="reminder-select-label"
              id="reminder-type"
              disabled={!reminderName}
              value={reminderType}
              label="Reminder Type"
              onChange={(evt) => {
                setReminderType(evt.target.value)
                if (!evt.target.value) {
                  setRun('');
                }
              }}
            >
              {contentType ? contentType.map((c: any) => (
                <MenuItem value={c.id} key={c.id}>{c.name}</MenuItem>
              )) : <MenuItem value="">None</MenuItem>}
            </Select>
            {!reminderName && (
              <FormHelperText>Enter a reminder name for type options</FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth sx={{marginTop: '10px'}}>
            <InputLabel id="run-label">Run for (X) days</InputLabel>
            <Select
              disabled={!reminderType}
              labelId="run-select-label"
              id="run-type"
              value={run}
              label="Run for (X) days"
              onChange={(evt) => setRun(evt.target.value)}
            >
              {runDays.map((r: any) => (
                <MenuItem value={r.nbr} key={r.nbr}>{r.label}</MenuItem>
              ))}
            </Select>
            {!reminderType && (
              <FormHelperText>Choose a reminder type for delivery options</FormHelperText>
            )}
          </FormControl>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox checked={useImage} onChange={(evt) => {
                  setUseImage(evt.target.checked)
                }}/>
              }
              label="Use image if available"
            />
          </FormGroup>
        </Box>
      )}
      <Button
        fullWidth
        sx={{marginTop: '10px'}}
        variant="contained"

        disabled={
          sponsor === "" ||
          firstName === "" ||
          lastName === "" ||
          userPhone === "" ||
          userPhone !== confirmPhone ||
          (sendReminder && (reminderName === "" || reminderType === "" || run === ""))
        }
        onClick={() => {
          mutation.mutate({
            sponsorId: sponsor,
            userPhoneNbr: userPhone,
            confirmPhoneNbr: confirmPhone,
            uniqueCode: uniqueCode,
            userFirstName: firstName,
            userLastName: lastName,
            sendReminder: sendReminder,
            reminderName: reminderName,
            activeStreakDays: activeStreak,
            totalInteractions: interactions,
            runDays: run,
            reminderType: reminderType,
            useImage: useImage,
            testing: true,
          })
        }}
      >
        {sendReminder && (reminderName !== "" && reminderType !== "" && run !== "") ?
          'Enroll User and Create Reminder' : 'Enroll User'
        }
      </Button>
      <AlertSnackbar message={message} isError={isError} onClose={() => setIsError(undefined)}/>
    </Box>
  )
}
