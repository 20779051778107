import {useMutation, UseMutationOptions} from "react-query";

export default function useSaveReminder(
  options: UseMutationOptions<any, any, any>
) {
  return useMutation<any, any, any>(
    async (req: any) => {
      // @ts-ignore
      const res = await fetch(`/api/reminder/${req.id}`, {
        method: 'POST',
        credentials: 'include',
        headers: new Headers({'Content-Type' : 'application/json'}),
        body: JSON.stringify(req),
      });

      if (res.status !== 200) {
        throw new Error();
      }
    },
    options
  );
}