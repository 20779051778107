import {
  Box,
  Button,
  Grid,
  TextField,
  Typography
} from "@mui/material";
import React, {useState} from "react";
import useRegisterUser from "../../hooks/mutations/useRegisterUser";
import AlertSnackbar from "../AlertSnackbar";
import {useSearchParams} from "react-router-dom";
import SignUpInfo from "./SignUpInfo";
import SignUpFooter from "./SignUpFooter";
import useIsMobile from "../../mui/useIsMobile";
import useGetSponsors from "../../hooks/queries/useGetSponsors";
import {IMaskInput} from "react-imask";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(
    function TextMaskCustom(props, ref) {
      const { onChange, ...other } = props;
      return (
          <IMaskInput
              {...other}
              mask="#00-000-0000"
              definitions={{
                '#': /[1-9]/,
              }}
              // @ts-ignore
              inputRef={ref}
              onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
              overwrite
          />
      );
    },
);

export default function SignUp() {
  const [searchParams] = useSearchParams();
  const isMobile = useIsMobile();

  const [firstName, setFirstName] = useState(searchParams.get('firstName') || '');
  const [lastName, setLastName] = useState(searchParams.get('lastName') || '');
  const [userPhone, setUserPhone] = useState(searchParams.get('phoneNumber') || '');
  const [emailAddr, setEmailAddr] = useState(searchParams.get('emailAddress') || '');
  const [externalId,] = useState(searchParams.get('externalId') || '');
  const [referralId,] = useState(searchParams.get('referralId') || searchParams.get('referralID') || '');
  const [userCreated, setUserCreated] = useState(false);
  const [isError, setIsError] = useState<boolean>();
  const [message, setMessage] = useState('');

  const {data: sponsor, isLoading, isError: sErr} = useGetSponsors(searchParams.get('sponsor'));

  const register = useRegisterUser({
    onSuccess: () => {
      setLastName('');
      setUserPhone('');
      setEmailAddr('');
      setUserCreated(true);
    },
    onError: () => {
      setIsError(true);
      setMessage("Unable to register, account may already be taken")
    }
  })

  return (
    <React.Fragment>
      <Box sx={{margin: '10px'}}>
        <Box sx={{display: 'flex', justifyContent: 'center', marginBottom: '16px'}}>
          <img src="/RxMinderLogo.svg" alt="Rx\Minder" height='60px' width="180px"/>
        </Box>
        <Grid container justifyContent="center">
          <SignUpInfo sponsor={sponsor} />
          <Grid container justifyContent="center" spacing={5}>
            <Grid item xs={12}>
              <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '10px'}}>
                {!userCreated && (
                  <>
                    <Typography sx={{textAlign: 'center', marginBottom: '10px'}}>
                      <span style={{fontWeight: 700, fontSize: "24px", color: '#583FBC'}}>Sign up for a</span>{' '}
                      {isMobile && <br/>}
                      <span style={{fontStyle: 'bold', fontWeight: 700, fontSize: "26px"}}>FREE 90 day beta!</span>
                    </Typography>
                    <Typography sx={{fontWeight: 400, fontSize: "16px", marginBottom: '20px', textAlign: 'center'}}>
                      Just fill out the provided form with your information and you’ll receive an email message from us to get you started. That’s it!
                    </Typography>
                    <TextField
                      required
                      fullWidth
                      sx={{marginBottom: "10px", maxWidth: '800px', '& fieldset': { borderRadius: '24px'}}}
                      id="first-name"
                      value={firstName}
                      label="First Name"
                      onChange={(evt) => setFirstName(evt.target.value)}
                    />
                    <TextField
                      required
                      fullWidth
                      sx={{marginBottom: "10px", maxWidth: '800px', '& fieldset': { borderRadius: '24px'}}}
                      id="last-name"
                      value={lastName}
                      label="Last Name"
                      onChange={(evt) => setLastName(evt.target.value)}
                    />
                    <TextField
                      required
                      fullWidth
                      sx={{marginBottom: "10px", maxWidth: '800px', '& fieldset': { borderRadius: '24px'}}}
                      id="user-phone"
                      value={userPhone}
                      label="Mobile Phone Number"
                      onChange={(evt) => {
                        setUserPhone(evt.target.value)
                      }}
                      InputProps={{
                        inputComponent: TextMaskCustom as any,
                      }}
                      type="phone"
                    />
                    <TextField
                      required
                      fullWidth
                      sx={{marginBottom: "10px", maxWidth: '800px', '& fieldset': { borderRadius: '24px'}}}
                      id="user-email"
                      value={emailAddr}
                      label="Email Address"
                      onChange={(evt) => setEmailAddr(evt.target.value)}
                      type="email"
                    />
                    <Button
                      fullWidth
                      disabled={isLoading || sErr || sponsor == null || (sponsor && Array.isArray(sponsor)) || register.isLoading || firstName === "" || lastName === "" || emailAddr === "" || userPhone === ""}
                      sx={{marginTop: '10px', maxWidth: '800px', borderRadius: '24px', padding: "10px"}}
                      variant="contained"
                      onClick={() => {
                        register.mutate({
                          firstName,
                          lastName,
                          phoneNumber: `+1${userPhone.replaceAll("-", "")}`,
                          email: emailAddr,
                          sponsor: sponsor.id,
                          externalId,
                          referralId,
                        });
                      }}
                    >
                      Sign Me Up!
                    </Button>
                  </>
                )}
                {userCreated && (
                  <Box sx={{ marginTop: '40px' }}>
                    <Typography variant="h4" sx={{ textAlign: 'center' }}>
                      Thanks for signing up {firstName}, and welcome to RX\Minder!
                    </Typography>
                    <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
                      There is an email heading your way to help you get started! Be sure to check your spam folder if you don't see it.
                    </Typography>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
          <AlertSnackbar message={message} isError={isError} onClose={() => setIsError(undefined)}/>
        </Grid>
      </Box>
      <SignUpFooter />
    </React.Fragment>
  )
}
