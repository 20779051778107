import {DataGrid, gridClasses, GridColDef, GridFilterModel, GridLinkOperator} from '@mui/x-data-grid';
import {alpha, Box, Button, Divider, styled} from "@mui/material";
import useGetContent from "../../hooks/queries/useGetContent";
import React, {useEffect, useState} from "react";
import LoadingPage from "../LoadingPage";
import TopAppBar from "../TopAppBar";
import useEditContentRow from "../../hooks/mutations/useEditContentRow";
import {useQueryClient} from "react-query";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {useNavigate} from "react-router-dom";
import useDeleteContent from "../../hooks/mutations/useDeleteContent";
import DeleteIcon from '@mui/icons-material/Delete';
import {useRecoilState, atom} from 'recoil';

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 150,
    sortable: false,
    filterable: false
  },
  {
    field: 'name',
    headerName: 'Name',
    sortable: false,
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 110,
    sortable: false
  },
  {
    field: 'content_text',
    headerName: 'Text',
    sortable: false,
    flex: 1,
  },
  {
    field: 'file_name',
    headerName: 'Filename',
    sortable: false,
    width: 150,
  },
  {
    field: 'created',
    headerName: 'Created',
    width: 200,
    filterable: false,
  },
];

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity,
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
          theme.palette.action.selectedOpacity +
          theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
}));

const filterState = atom<GridFilterModel>({
  key: 'filterState',
  default: {items: [], linkOperator: GridLinkOperator.And},
});

export default function ContentView() {
  const [filter, setFilter] = useRecoilState(filterState);
  const queryClient = useQueryClient();
  const {data, isLoading} = useGetContent();
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState<any>([]);

  const mutation = useEditContentRow({
    onSuccess: () => {
      queryClient.invalidateQueries('content');
    }
  });

  const delMutation = useDeleteContent({
    onSuccess: () => {
      queryClient.invalidateQueries('content');
    }
  })

  const [rows, setRows] = useState<any>([]);

  useEffect(() => {
    if (data) {
      const r = data.map((d: any) => {
        return {
          id: d.id,
          name: d.name,
          type: d.type,
          content_text: d.content_text,
          file_name: d.file_name,
          created: new Date(d.created).toLocaleString()
        }
      });
      setRows(r);
    }
  }, [data])

  if (isLoading) {
    return <LoadingPage/>
  }

  return (
    <Box sx={{ width: '100%' }}>
      <TopAppBar title="Content Manager" />
      <Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: '5px'}}>
        <div style={{flexGrow: 1}}/>
        <Button
          onClick={() => navigate('/add-content')}
          size="large"
          startIcon={<AddCircleOutlineIcon />}
          sx={{marginRight: '50px'}}
        >
          Create New
        </Button>
      </Box>
      <Divider />
      <StripedDataGrid
        onCellEditCommit={(c) => {
          mutation.mutate(c)
        }}
        onSelectionModelChange={(s, _c) => {
          const r = s.map((v) => {
            const file = rows.find((a: any) => a.id === v);
            return {
              id: v,
              file: !!file && file.file_name
            }
          });
          setSelectedRows(r);
        }}
        rows={rows}
        columns={columns}
        pageSize={12}
        rowsPerPageOptions={[12]}
        autoHeight
        disableSelectionOnClick
        checkboxSelection
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }
        onFilterModelChange={(m, _d) => {
          setFilter(m);
        }}
        filterModel={filter}
      />
      <Box sx={{ display: 'flex', marginTop: '10px', justifyContent: 'center', flexDirection: 'row'}}>
        <Button
          onClick={() => delMutation.mutate({ ids: selectedRows })}
          size="large"
          variant="contained"
          disabled={selectedRows.length === 0}
          startIcon={<DeleteIcon />}
          sx={{marginRight: '50px'}}
        >
          Delete
        </Button>
        <Button
          onClick={() => {
            navigate(`/edit-content/${selectedRows[0].id}`);
          }}
          size="large"
          variant="contained"
          disabled={selectedRows.length !== 1}
          startIcon={<DeleteIcon />}
          sx={{marginRight: '50px'}}
        >
          Edit
        </Button>
      </Box>
    </Box>
  );
}
