import React from 'react';
import {Box, Card} from "@mui/material";
import TopAppBar from "./TopAppBar";
import RemindersByInterval from "./data/RemindersByInterval";
import SentOptIn from "./data/SentOptIn";

export default function Kpi() {
  return (
    <Box sx={{display: 'flex'}}>
      <TopAppBar title="Metrics"/>
      <Card sx={{marginTop: '80px', backgroundColor: 'white', maxWidth: '700px', marginLeft: '15px'}}>
        <RemindersByInterval />
      </Card>
      <Card sx={{marginTop: '80px', backgroundColor: 'white', maxWidth: '700px', marginLeft: '15px', display: 'flex'}}>
        <SentOptIn />
      </Card>
    </Box>
  );
}