import {useQuery} from "react-query";
import React from "react";

const FilePreview: React.FC<{ file: File }> = ({ file }) => {
  const { data: url } = useQuery(`file-preview-${file.name}`, async () =>
    URL.createObjectURL(file)
  );

  return url ? <img width={300} alt="file preview" src={url} /> : <span>Loading...</span>;
}

export default FilePreview;