import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import useGetSponsors from "../hooks/queries/useGetSponsors";
import {Box, Button, TextField} from "@mui/material";
import {useState} from "react";
import useCreateBroadcast from "../hooks/mutations/useCreateBroadcast";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs, {Dayjs} from "dayjs";
import LoadingPage from "./LoadingPage";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers";
import TopAppBar from "./TopAppBar";
import AlertSnackbar from "./AlertSnackbar";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(sponsor: string, sponsors: string[], theme: Theme) {
  return {
    fontWeight:
      sponsors.indexOf(sponsor) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function Broadcast() {
  const {data: sponsors, isLoading: sponsorLoading} = useGetSponsors(null);
  const theme = useTheme();
  const [text, setText] = useState<string>('');
  const [start, setStart] = useState<Dayjs | null>(dayjs());
  const [end, setEnd] = useState<Dayjs | null>(dayjs());
  const [isError, setIsError] = useState<boolean>();
  const [message, setMessage] = useState('');

  const mutation = useCreateBroadcast({
    onSuccess: () => {
      setText('');
      setStart(null);
      setEnd(null);
      setSponsor([]);
      setIsError(false);
      setMessage('Successfully saved Broadcast!');
    },
    onError: () => {
      setIsError(false);
      setMessage('Successfully saved Broadcast!');
    }
  });

  const [sponsor, setSponsor] = React.useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof sponsor>) => {
    const {
      target: { value },
    } = event;
    setSponsor(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  if (sponsorLoading) {
    return (
      <Box sx={{minWidth: '600px'}}>
        <LoadingPage/>
      </Box>
    );
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginLeft: '10px',
      marginRight: '10px'
    }}>
      <TopAppBar title="Broadcast" />
      <FormControl sx={{ marginBottom: '10px', width: '100%' }}>
        <InputLabel id="demo-multiple-name-label">Name</InputLabel>
        <Select
          fullWidth
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          multiple
          value={sponsor}
          onChange={handleChange}
          input={<OutlinedInput label="Sponsor" />}
          MenuProps={MenuProps}
        >
          {sponsors.map((s: any) => (
            <MenuItem
              key={s.id}
              value={s.id}
              style={getStyles(s.id, sponsors, theme)}
            >
              {s.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box sx={{display: 'flex', flexDirection: 'row', marginBottom: '10px'}}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label="Start Date"
            inputFormat="MM/DD/YYYY"
            value={start}
            onChange={(newValue: Dayjs | null) => {setStart(newValue)}}
            renderInput={(params) => <TextField {...params} fullWidth sx={{marginRight: '5px'}} />}
          />
          <DesktopDatePicker
            label="End Date"
            inputFormat="MM/DD/YYYY"
            value={end}
            onChange={(newValue: Dayjs | null) => {setEnd(newValue)}}
            renderInput={(params) => <TextField {...params} fullWidth sx={{marginLeft: '5px'}} />}
          />
        </LocalizationProvider>
      </Box>
      <TextField
        required
        fullWidth
        multiline
        maxRows={4}
        rows={4}
        sx={{marginBottom: "10px"}}
        id="content-id"
        value={text}
        label="Message"
        onChange={(evt) => setText(evt.target.value)}
      />
      <Button
        fullWidth
        sx={{marginBottom: '10px'}}
        variant="contained"
        disabled={sponsor.length === 0 || text === '' || start === null || end === null}
        onClick={() => { mutation.mutate({message: text, sponsors: sponsor, start, end}) }}
      >
        Save
      </Button>
      <AlertSnackbar message={message} isError={isError} onClose={() => setIsError(undefined)}/>
    </Box>
  );
}
