import TopAppBar from "./TopAppBar";
import { ContentStyle } from "../style/ContentStyle";
import {
    Box,
    List,
    ListItem,
    ListItemText,
    Typography,
} from "@mui/material";
import useGetVariables from "../hooks/queries/useGetVariables";
import LoadingPage from "./LoadingPage";

export default function About() {

    const { data: variables, isLoading: vLoading } = useGetVariables();

    if(vLoading){
        return <LoadingPage/>
    }
    const currentDate = new Date();
    const buildTime = new Date(variables[0].time);
    const buildTimeFormat = buildTime.toLocaleString();

    const copyright = `Copyright © ${currentDate.getFullYear()} Rx|Minder`;
    const buildDate = `Build Date: ${buildTimeFormat}`

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginLeft: '10px',
            marginRight: '10px'
        }}>
            <>
            <TopAppBar title={"About"} />
            <Box
                display="flex" width="100%" height="100%" alignItems="center" justifyContent="center">
            <ContentStyle>
                <List>
                    {[buildDate, copyright].map((text) => (
                        <ListItem key={text}>
                            <ListItemText disableTypography>
                                <Typography variant="h5">{text}</Typography>
                            </ListItemText>
                        </ListItem>
                    ))}
                </List>
            </ContentStyle>
            </Box>
            </>
        </Box>
    )
}