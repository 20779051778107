import { createTheme } from '@mui/material';
import palette from './palette';
import components from './components';

const theme = createTheme({
  palette: palette.light,
  mixins: {
    navigationWidth: 240
  },
  components
});

export default theme;
