import {useQuery} from "react-query";

export default function useGetRemindersByInterval(interval: number, sponsor: string) {
  return useQuery(`reminders-interval-${interval}-${sponsor}`, async () => {
    // @ts-ignore
    const res = await fetch(`/api/metrics/reminders?interval=${interval}&sponsor=${sponsor}`,{
      method: 'GET',
      credentials: 'include',
      headers: new Headers({'Content-Type' : 'application/json'}),
    });

    return res.json();
  }, {retry: false, refetchInterval: 60000})
}