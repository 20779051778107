import React from 'react';
import { RouteProps } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Watertower from "./components/Watertower";
import Settings from "./components/Settings";
import Config from "./components/Config";
import Message from "./components/Message";
import Reminders from "./components/Reminders";
import About from './components/About';
import Stats from './components/Stats';
import Kpi from './components/Kpi';
import ContentView from './components/content-manager/ContentView';
import ContentAction from './components/content-manager/ContentAction';
import Broadcast from "./components/Broadcast";
import SignUp from "./components/register/SignUp";

const appRoutes: RouteProps[] = [
  {
    path: '/stats',
    element: <Stats />
  },
  {
    path: '/about',
    element: <About />
  },
  {
    path: '/config',
    element: <Config />
  },
  {
    path: '/watertower',
    element: <Watertower />
  },
  {
    path: '/settings',
    element: <Settings />
  },
  {
    path: '/message',
    element: <Message />
  },
  {
    path: '/reminders',
    element: <Reminders />
  },
  {
    path: '/8542aa4c-f0e9-454c-a33d-0b44054d406b/metrics',
    element: <Kpi />
  },
  {
    path: '/content',
    element: <ContentView />
  },
  {
    path: '/add-content',
    element: <ContentAction />
  },
  {
    path: '/add-broadcast',
    element: <Broadcast />
  },
  {
    path: `/register`,
    element: <SignUp />,
  },
  {
    path: '/edit-content/:id',
    element: <ContentAction />
  },
  {
    path: '/',
    element: <Dashboard />
  }
];

export default appRoutes;
