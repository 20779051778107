import {useQuery} from "react-query";

export default function useGetPresignedUrl(filename: string | undefined) {
  return useQuery(['url', filename], async () => {
    const res = await fetch(`/api/presigned_url/get/${filename}`, {
      method: 'GET',
      credentials: 'include',
      // @ts-ignore
      headers: new Headers({'Content-Type': 'application/json'}),
    });

    return res.json();
  }, {retry: false, refetchInterval: 3600, enabled: !!filename})
}