import TopAppBar from "./TopAppBar";
import useGetReminders from "../hooks/queries/useGetReminders";
import { useEffect, useState } from "react";

import {
    Box,
    Divider,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import CircularProgress, { CircularProgressProps, } from '@mui/material/CircularProgress';
import LoadingPage from "./LoadingPage";
import useGetConfig from "../hooks/queries/useGetConfig";
import ErrorContent from "./ErrorContent";
import useGetAllReminders from "../hooks/queries/useGetAllReminders";
export default function Reminders() {
  const { data: reminder, isLoading: rLoading, isError: rError } = useGetReminders();
  const { data: me, isLoading: meLoading, isError: meError } = useGetConfig();
  const { data: allReminders, isLoading: allRLoading, isError: allRError } = useGetAllReminders();
  const [reminders, setReminders] = useState<any[]>([]);

    const styleLeft = {
        marginTop: "20px",
        marginBottom: "20px",
        marginLeft: "10%",
        fontWeight: "bold",
        fontSize: "1.15rem",
        display: "inline"
    }

    const styleRight = {
        marginTop: "20px",
        marginBottom: "20px",
        marginRight: "15%",
        fontWeight: "bold",
        fontSize: "1.15rem",
        display: "inline"
    }

    const styleDivider = {
        width: "100%", 
        border: "1px solid #3F4CA0",
        marginBottom: "10px",
    }
    

    let totalMessageCount = 0;
    let thirtyMessageCount = 0; 
    let sevenMessageCount = 0;
    let totalStateCount = 0;
    let thirtyStateCount = 0; 
    let sevenStateCount = 0;
    let totalScore = 0;
    let thirtyScore = 0;
    let sevenScore = 0;

    const state = "REPLIED"
    const currentDate = new Date();
    const calcAllScore = () => {
        allReminders.reminders.forEach((r: any) => {
            r.messages.forEach((m: any) => {
                let messageDate = new Date(`${m.created}`);
                let difference = Math.abs(currentDate.getTime() - messageDate.getTime());
                let dayDif = Math.ceil(difference / (1000 * 60 * 60 * 24));

                //Total Count
                totalMessageCount += 1;
                if(m.state === state) {
                    totalStateCount += 1;
                }

                //Thirty Day Count
                if(dayDif <= 30){
                    thirtyMessageCount += 1;
                    if(m.state === state) {
                        thirtyStateCount += 1;
                    }

                //Seven Day Count
                    if(dayDif <= 7){
                        sevenMessageCount += 1;
                        if(m.state === state) {
                            sevenStateCount += 1;
                        }
                    }
                }
            })
        })

        totalScore = ((totalStateCount) / totalMessageCount) * 100;
        thirtyScore = ((thirtyStateCount) / thirtyMessageCount) * 100;
        sevenScore = ((sevenStateCount) / sevenMessageCount) * 100;
    }

  useEffect(() => {
    if (reminder) {
      setReminders(reminder.reminders);
    }
  }, [reminder]);

  if (rLoading || meLoading || allRLoading) {
    return <LoadingPage />
  }

  if (meError || rError || allRError) {
    return(
        <>
        <TopAppBar title="Stats"/>
        <ErrorContent />
      </>
    )
  }

  function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number, size: number },
  ) {

    let value = props.value;
    let color = "red";
    if (value <= 50) {
      color = "red";
    } else if (value > 50 && value < 80) {
      color = "yellow";
    } else {
      color = "green";
    }

    return (
      <Box sx={{ position: 'relative', display: 'grid', width: props.size}}>
        <CircularProgress sx={{gridColumn: 1, gridRow: 1}} disableShrink variant="determinate" value={100} size={props.size} thickness={4.5} style={{color: "rgba(0, 0, 0, 0.2)"}}/>
        <CircularProgress sx={{gridColumn: 1, gridRow: 1}} variant="determinate" {...props} size={props.size} thickness={4.5} style={{color: color}}/>
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
            sx={{fontSize: (props.size / 5)}}
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }
  

  console.log(reminders);

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginLeft: '10px',
            marginRight: '10px'
        }}>
            <TopAppBar title={"Stats"} />
            <Typography variant="h4">{me.customer.preferredName}{calcAllScore()}</Typography>
            
            <Box sx={{display: "flex", marginBottom: "20px", alignItems: "center"}}>
            <Typography variant="subtitle1" sx={{color:"gray"}}>Adherence Score</Typography>
            <Divider sx={{width: "60%", ml: "5px"}}/>
            </Box>

            <Box m="auto">
            <CircularProgressWithLabel value={sevenScore} size={150}/><Typography variant="h6" sx={{color:"gray", textAlign: "center", mt:"5px"}}>7 Day</Typography>
            </Box>

            <Grid container spacing={2} sx={{paddingTop: "20px"}} justifyContent="space-around" alignItems="center">
              <Grid item>  
                <CircularProgressWithLabel value={thirtyScore} size={75}/><Typography variant="subtitle1" sx={{color:"gray", textAlign: "center", mt:"5px"}}>30 Day</Typography>
              </Grid>
              <Grid item>
                <CircularProgressWithLabel value={totalScore} size={75}/><Typography variant="subtitle1" sx={{color:"gray", textAlign: "center", mt:"5px"}}>All Time</Typography>
              </Grid>
            </Grid>

            <Box sx={{display: "flex", alignItems: "center", mt: "20px"}}>
            <Typography variant="subtitle1" sx={{color:"gray"}}>Reminder Stats</Typography>
            <Divider sx={{width: "60%", ml: "5px"}}/>
            </Box>

            <TableContainer sx={{mb: "10px"}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{fontWeight: "bold"}}>Reminder</TableCell>
                            <TableCell sx={{fontWeight: "bold"}} align="center">Streak</TableCell>
                            <TableCell sx={{fontWeight: "bold"}} align="center">Completed</TableCell>
                            <TableCell sx={{fontWeight: "bold"}} align="center">Days</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reminders.map((r: any) => (
                            <TableRow key={r.name}>
                                <TableCell style={{width: "25%"}}>{r.name}</TableCell>
                                <TableCell align="center" style={{width: "25%"}}>{r.streak}</TableCell>
                                <TableCell align="center" style={{width: "25%"}}>{r.interactions}</TableCell>
                                <TableCell align="center" style={{width: "25%"}}>{r.messages.length}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer> 
            

            <Typography variant="subtitle1" sx={{mb: "10px", color:"gray"}}>Personal Stats</Typography>
            <Divider style={styleDivider}/>
            <Box style={{display: "flex", justifyContent: "space-between", marginBottom: "10px"}}>
            <Typography style={styleLeft}>Enrollment Date:</Typography>
            <Typography style={styleRight}>{new Date(me.config.enrollmentHistory[0].created).toLocaleDateString()}</Typography>
            </Box>
            <Divider style={styleDivider}/>

        </Box>
    )
}