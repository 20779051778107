import {useQuery} from "react-query";

export default function useGetTimeZones() {
  return useQuery('time-zones', async () => {
    // @ts-ignore
    const res = await fetch('/api/time_zones',{
      method: 'GET',
      credentials: 'include',
      headers: new Headers({'Content-Type' : 'application/json'}),
    });

    return res.json();
  }, {retry: false})
}