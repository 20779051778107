import {useQuery} from "react-query";

export default function useGetVariables() {
  return useQuery('variables', async () => {
    // @ts-ignore
    const res = await fetch('/api/variables',{
      method: 'GET',
      credentials: 'include',
      headers: new Headers({'Content-Type' : 'application/json'}),
    });

    return res.json();
  }, {retry: false})
}