import {useQuery} from "react-query";

export default function useGetSponsors(id: string | null) {
  return useQuery(id ? ['sponsor', `${id}`] : ['sponsors'], async () => {
    // @ts-ignore
    const res = await fetch(id ? `/api/sponsors/${id}` : '/api/sponsors',{
      method: 'GET',
      credentials: 'include',
      headers: new Headers({'Content-Type' : 'application/json'}),
    });

    return res.json();
  }, {retry: false})
}