import {Alert, Snackbar} from "@mui/material";
import React, {useEffect, useState} from "react";
import {AlertState} from "../types/alertState";

type AlertProps = {
  isError?: boolean;
  message: string;
  onClose?: () => void;
}

export default function AlertSnackbar({isError, message, onClose}: AlertProps) {
  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    severity: 'success',
    message: ''
  });

  useEffect(() => {
    if (isError !== undefined) {
      setAlertState({
        open: true,
        message: message,
        severity: isError ? 'error' : 'success'
      });
    }
  }, [isError, message])

  const handleClose = () => {
    setAlertState({open: false, severity: 'error', message: ''});
    onClose ? onClose() : console.log('closed alert');
  };

  return (
    <Snackbar
      open={alertState.open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{vertical: "top", horizontal: 'center'}}
    >
      <Alert onClose={handleClose} severity={alertState.severity} sx={{width: '100%'}}>
        {alertState.message}
      </Alert>
    </Snackbar>
  )
}