import {Box, Link, Typography} from "@mui/material";
import React from "react";

export default function SignUpFooter() {
  return (
    <Box sx={{
      height: '144px',
      backgroundColor: '#041434',
      marginTop: '60px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    }}>
      <Box sx={{alignSelf: 'center'}}>
        <img src="/WhiteRxminderLogo.svg" alt="RxMinder" height="64px" width="174px" />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
        <Link underline="none" sx={{ color: 'white'}} href="https://app.termly.io/document/terms-of-use-for-website/7f38a700-a782-46b1-8450-fd236c656647">
          Terms of Use
        </Link>
        <Link underline="none" sx={{ color: 'white'}} href="https://app.termly.io/document/privacy-policy/950ad8ba-f805-4c71-a456-8bf37d9f3ed9">
          Privacy Policy
        </Link>
      </Box>
      <Box sx={{alignSelf: 'center'}}>
        <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.6)' }}>
          ©RX\Minder 2022. All Right Reserved.
        </Typography>
      </Box>
    </Box>
  )
}