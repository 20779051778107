import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import TopAppBar from "../TopAppBar";
import useGetContentTypes from "../../hooks/queries/useGetContentTypes";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import useCreateContent from "../../hooks/mutations/useCreateContent";
import {useQueryClient} from "react-query";
import AlertSnackbar from "../AlertSnackbar";
import {useDropzone} from "react-dropzone";
import FilePreview from "./FilePreview";
import useGetContentById from "../../hooks/queries/useGetContentById";
import useGetPresignedUrl from "../../hooks/queries/useGetPresignedUrl";
import FilePreviewS3 from "./FilePreviewS3";

export default function ContentAction() {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const [file, setFile] = useState<File>();
  const [s3File, setS3File] = useState<string>();
  const [message, setMessage] = useState<string>('');
  const [isError, setIsError] = useState<boolean>();
  const [category, setCategory] = useState<string>('DADJOKE');
  const [name, setName] = useState<string>('');
  const [contentId, setContentId] = useState<string>('');
  const [text, setText] = useState<string>('');
  const navigate = useNavigate();
  const {data: contentTypes} = useGetContentTypes(undefined, true);
  const {data: content} = useGetContentById(id);
  const {data: presigned} = useGetPresignedUrl(s3File);

  const mutation = useCreateContent({
    onSuccess: () => {
      queryClient.invalidateQueries('content');
      queryClient.invalidateQueries(`file-preview-${file?.name}`);
      queryClient.invalidateQueries(['url', s3File]);
      setMessage('Successfully created content');
      setIsError(false);
      setS3File(undefined);
      setFile(undefined);
      navigate('/content');
    },
    onError: () => {
      setMessage('Unable to create content');
      setIsError(true);
    }
  });

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDropRejected: (rejection, event) => {
      setMessage('Unable to upload file');
      setIsError(true);
    },
    onDropAccepted: (accepted, event) => {
      setFile(accepted[0]);
    },
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
      'image/gif': ['.gif']
    },
    maxFiles: 1
  });

  useEffect(() => {
    if (content && content.length === 1) {
      const c = content[0];
      setCategory(c.type);
      setContentId(c.id);
      setName(c.name);
      setText(c.content_text);

      if (c.file_name) {
        setS3File(c.file_name);
      }
    }
  }, [content])

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginLeft: '10px',
      marginRight: '10px'
    }}>
      <TopAppBar title="Content Manager - Create New" showMenu={false}/>
      <FormControl sx={{marginBottom: '10px'}}>
        <InputLabel id="sponsor-label">Category</InputLabel>
        <Select
          required
          labelId="category-select-label"
          id="category-select"
          value={category}
          label="Category"
          onChange={(evt) => setCategory(evt.target.value)}
        >
          {
            contentTypes ? contentTypes.map((s: any) => (
              <MenuItem value={s.id} key={s.id}>{s.name}</MenuItem>
            )) : <MenuItem disabled>None</MenuItem>
          }
        </Select>
      </FormControl>
      <TextField
        required
        fullWidth
        sx={{marginBottom: "10px", marginRight: '5px'}}
        id="content-id"
        value={contentId}
        label="ID"
        onChange={(evt) => setContentId(evt.target.value)}
      />
      <TextField
        fullWidth
        sx={{marginBottom: "10px", marginRight: '5px'}}
        id="content-name"
        value={name}
        label="Name"
        onChange={(evt) => setName(evt.target.value)}
      />
      <TextField
        required
        fullWidth
        multiline
        maxRows={4}
        rows={4}
        sx={{marginBottom: "10px", marginRight: '5px'}}
        id="content-id"
        value={text}
        label="Text"
        onChange={(evt) => setText(evt.target.value)}
      />
      {file === undefined && s3File === undefined && (
        <Box
          {...getRootProps()}
          sx={(theme) => ({
            backgroundColor: theme.palette.grey[300],
            minHeight: theme.spacing(32)
          })}
        >
          <input {...getInputProps()} />
          <Box
            sx={(theme) => ({
              border: `3px dashed ${theme.palette.primary.light}`,
              margin: theme.spacing(3),
              padding: theme.spacing(6),
              textAlign: 'center'
            })}
          >
            <Box sx={(theme) => ({ marginBottom: theme.spacing(1) })}>
              {isDragActive ? (
                <p>Drop the files here</p>
              ) : (
                <p>Click the button or drag and drop a file to attach.</p>
              )}
            </Box>
            <Button variant="contained" color="primary">
              Upload file
            </Button>
          </Box>
        </Box>
      )}
      <Box sx={{
        width: 300,
        margin: '0 auto',
        display: `${file !== undefined || presigned !== undefined ? 'flex' : 'none'}`,
        flexDirection: 'column',
        justifyContent: 'center'
      }}>
        {file !== undefined && <FilePreview file={file} />}
        {presigned !== undefined && <FilePreviewS3 s3Url={presigned.url} />}
        <Button
          sx={{margin: '10px'}}
          variant="contained"
          onClick={() => {
            setFile(undefined);
            setS3File(undefined);
          }}
        >
          Reset
        </Button>
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
        <Button
          sx={{margin: '10px'}}
          variant="contained"
          onClick={() => {
            mutation.mutate({req: { id: contentId, type: category, name, text }, file, s3File })
          }}
        >
          Save
        </Button>
        <Button
          sx={{margin: '10px'}}
          variant="contained"
          onClick={() => {
            navigate('/content')
          }}
        >
          Discard
        </Button>
      </Box>
      <AlertSnackbar message={message} isError={isError} onClose={() => setIsError(undefined)}/>
    </Box>
  )
}