import {useQuery} from "react-query";

export default function useGetAllReminders() {
  return useQuery('all_reminders', async () => {
    // @ts-ignore
    const res = await fetch('/api/all_reminders',{
      method: 'GET',
      credentials: 'include',
      headers: new Headers({'Content-Type' : 'application/json'}),
    });

    return res.json();
  }, {retry: false, refetchInterval: 7500})
}