import {useMutation, UseMutationOptions} from "react-query";

export default function useEditContentRow(
  options: UseMutationOptions<any, any, any>
) {
  return useMutation<any, any, any>(
    async (req: any) => {
      // @ts-ignore
      return await fetch(`/api/content/${req.id}`, {
        method: 'PUT',
        credentials: 'include',
        headers: new Headers({'Content-Type' : 'application/json'}),
        body: JSON.stringify(req),
      });
    },
    options
  );
}