import {useQuery} from "react-query";

export default function useGetReminders() {
  return useQuery('reminders', async () => {
    // @ts-ignore
    const res = await fetch('/api/reminders',{
      method: 'GET',
      credentials: 'include',
      headers: new Headers({'Content-Type' : 'application/json'}),
    });

    return res.json();
  }, {retry: false})
}