import {Box, AppBar, Toolbar, IconButton, Typography, Drawer, ListItemButton} from '@mui/material'
import {List, ListItem} from '@mui/material';
import {ReactComponent as SettingsIcon} from "../svgicons/SettingsIcon.svg";
import {ReactComponent as ReminderIcon} from "../svgicons/ReminderIcon.svg";
import {ReactComponent as StatsIcon} from "../svgicons/StatsIcon.svg";
import {ReactComponent as AboutIcon} from "../svgicons/About.svg";
import {ReactComponent as HelpIcon} from "../svgicons/Help-FAQ.svg";
import MenuIcon from '@mui/icons-material/Menu';
import Clear from '@mui/icons-material/Clear';
import React, {useEffect, useState} from 'react';
import useGetMe from "../hooks/queries/useGetMe";
import useIsMobile from "../mui/useIsMobile";

type AppBarProps = {
  title: string;
  showMenu?: boolean;
}

const style = {
  height: "30px",
  width: "30px",
  paddingRight: "20px",
  fill: "#3F4CA0",
}

const icons = [<SettingsIcon style={style}/>,
  <ReminderIcon style={style}/>,
  <StatsIcon style={style}/>,
  <HelpIcon style={style}/>,
  <AboutIcon style={style}/>,
  <StatsIcon style={style}/>];

export default function TopAppBar({title, showMenu}: AppBarProps) {
  const {data: me} = useGetMe();
  const isMobile = useIsMobile();
  const [sponsor, setSponsor] = useState("");

  useEffect(() => {
    if (me) {
      setSponsor(me.sponsor.id);
    }
  }, [me])

  let [open, setOpen] = React.useState(false);

  const handleDrawer = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };


  function HamburgerMenu(props: { isOpen: boolean; }) {
    const isOpen = props.isOpen;
    if (isOpen) {
      return <Clear/>;
    } else {
      return <MenuIcon/>;
    }
  }

  const getLink = (option: String, sponsor: string) => {
    if (option === 'Help & FAQ') {
      const scriptdrop = "https://rxminder.notion.site/Rx-Minder-Help-and-FAQ-s-ScriptDrop-e04006a990f9410da5838f8c8e2af8e7";
      const isScript = sponsor === "scriptdrop";
      return {
        link: isScript ? scriptdrop : 'https://rxminder.notion.site/Rx-Minder-Help-and-FAQ-s-14b91e67b20c40dfa31e2c84f502bafe',
        target: "_blank"
      };
    }
    return {link: `./${option.toLowerCase()}`, target: "_self"}
  };

  return (
    <Box sx={{marginBottom: '80px'}}>
      <AppBar position="fixed" sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}>
        <Toolbar>
          {showMenu !== false && (
            <IconButton
              color="inherit"
              edge="start"
              onClick={handleDrawer}
            >
              <HamburgerMenu isOpen={open}/>
            </IconButton>
          )}
          <Typography variant="h4" sx={{
            marginLeft: '10px',
            display: {xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block'}
          }}>
            {title}
          </Typography>
          <Typography variant="h6" sx={{marginLeft: '10px', display: {md: 'none', lg: 'none', xl: 'none'}}}>
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: isMobile ? "75%" : "25%",
          '& .MuiDrawer-paper': {
            width: isMobile ? "75%" : "25%",
            boxSizing: 'border-box',
          },
        }}
        open={open}
        variant="persistent"
        anchor="left"
      >
        <Box
          display="flex"
          width="100%"
          height="100%"
          position="fixed"
          top="10%"
          justifyContent="left"
        >
          <List>
            {['Settings', 'Reminders', 'Stats', 'Help & FAQ', 'About'].map((text, index) => (
              <ListItem key={text}>
                <ListItemButton
                  sx={{marginBottom: "20px"}}
                  component="a"
                  href={getLink(text, sponsor).link}
                  target={getLink(text, sponsor).target}
                >
                  {icons[index]}
                  <Typography
                    sx={{fontSize: "1.25rem", textAlign: "left", fontWeight: "bold"}}
                  >{text}
                  </Typography>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </Box>
  )
}
