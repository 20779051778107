import HelpIcon from '@mui/icons-material/Help';
import {ClickAwayListener, IconButton, Tooltip, Typography} from "@mui/material";
import React, {useState} from "react";

interface Props {
  text: React.ReactFragment;
}

export function HelpTooltip({ text }: Props) {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={text}
          arrow
        >
          <IconButton onClick={handleTooltipOpen}>
            <HelpIcon sx={{fontSize: "medium"}} />
          </IconButton>
        </Tooltip>
      </div>
    </ClickAwayListener>
  )
}