import {Box, List, ListItem, ListItemText, Typography} from "@mui/material";
import TopAppBar from "./TopAppBar";
import useGetConfig from "../hooks/queries/useGetConfig";
import LoadingPage from "./LoadingPage";
import ErrorContent from "./ErrorContent";

export default function Config() {
  const {data: config, isLoading, isError} = useGetConfig();

  if (isLoading) {
    return <LoadingPage />
  }

  if (isError) {
    return (
      <>
        <TopAppBar title="RX|Minder Config" />
        <ErrorContent />
      </>
    )
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginLeft: '10px',
      marginRight: '10px'
    }}>
      <TopAppBar title="RX|Minder Config" />
      <Typography variant="h1">
        {config.customer.preferredName}'s Config
      </Typography>
      <Typography variant="h2" sx={{marginTop: '10px'}}>
        Enrollment History
      </Typography>
      <List>
        {config ? config.enrollmentHistory.map((eh: any) => (
          <ListItem>
            <ListItemText primary={`${eh.state} on ${new Date(eh.created).toLocaleString()}`} />
          </ListItem>
        )) : <ListItem><ListItemText primary="No Enrollment History" /></ListItem>}
      </List>
      <List>
        {config ? config.reminders.map((cr: any) => (
         <ListItem>
           <ListItemText primary={`${cr.name} (${cr.type})`}/>
           <ListItemText secondary={`Created: ${new Date(cr.created).toLocaleString()}`} />
           <ListItemText secondary={`Last Sent: ${new Date(cr.sendTime).toLocaleString()}`} />
           <ListItemText secondary={`${cr.runDays < 0 ? 'Infinite' : cr.runDays} day(s) remaining`} />
         </ListItem>
        )) : <ListItem><ListItemText primary="No Reminders" /></ListItem>}
      </List>
    </Box>
  )
}