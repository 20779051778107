import {ContentStyle} from "../style/ContentStyle";
import {Typography} from "@mui/material";

export default function ErrorContent() {
  return (
    <ContentStyle>
      <Typography variant="h5">
        You are not authorized to view this profile. If viewing from a "SETTINGS" link you have requested, please
        click the text link again, or re-request "SETTINGS"
      </Typography>
    </ContentStyle>
  )
}