import useGetCustomerMetrics from "../../hooks/queries/useGetCustomerMetrics";
import LoadingPage from "../LoadingPage";
import {Box, FormControl, InputLabel, MenuItem, Select, TableFooter, TablePagination, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import useGetSponsors from "../../hooks/queries/useGetSponsors";

export default function SentOptIn() {
  const [sponsor, setSponsor] = useState<string>('none');
  const [status, setStatus] = useState<string>('none');
  const {data, isLoading, isError} = useGetCustomerMetrics(sponsor, status);
  const {data: sponsors, isLoading: sponsorLoading} = useGetSponsors(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (isLoading || sponsorLoading) {
    return (
      <Box sx={{minWidth: '600px'}}>
        <LoadingPage/>
      </Box>
    );
  }

  if (!isLoading && (isError || (!data && !data.customers))) {
    return (
      <Typography variant="h4">
        No data available for customers.
      </Typography>
    )
  }

  return (
    <TableContainer component={Paper}>
      <FormControl sx={{margin: '10px', minWidth: '150px'}}>
        <InputLabel id="sponsor-label">Sponsor Filter</InputLabel>
        <Select
          labelId="sponsor-select-label"
          id="sponsor-type"
          value={sponsor}
          label="Sponsor Filter"
          onChange={(evt) => {
            setSponsor(evt.target.value);
          }}
        >
          <MenuItem value="none">None</MenuItem>
          {
            sponsors ? sponsors.map((s: any) => (
              <MenuItem value={s.id} key={s.id}>{s.name}</MenuItem>
            )) : <MenuItem disabled>None</MenuItem>
          }
        </Select>
      </FormControl>
      <FormControl sx={{margin: '10px', minWidth: '150px'}}>
        <InputLabel id="sponsor-label">Status Filter</InputLabel>
        <Select
          labelId="status-select-label"
          id="status-type"
          value={status}
          label="Status Filter"
          onChange={(evt) => {
            setStatus(evt.target.value);
          }}
        >
          <MenuItem value="none">None</MenuItem>
          {
            ['REQ_OPT_IN', 'OPTED_IN', 'OPTED_OUT', 'SNT_OPT_IN'].map((s: any) => (
              <MenuItem value={s} key={s}>{s}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
      <Typography sx={{padding: '5px', textAlign: 'center'}}>Customers</Typography>
      <Table sx={{minWidth: 600}} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="left">Number</TableCell>
            <TableCell align="left">Status</TableCell>
            <TableCell align="left">Date Created</TableCell>
            <TableCell align="left">Time Created</TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{overflow: 'scroll'}}>
          {(rowsPerPage > 0
              ? data.customers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : data.customers
          ).map((row: any) => (
            <TableRow
              key={row.name}
              sx={{'&:last-child td, &:last-child th': {border: 0}}}
            >
              <TableCell component="th" scope="row">{row.name ? row.name : row.prefferedName}</TableCell>
              <TableCell align="left">{row.number}</TableCell>
              <TableCell align="left">{row.enrollState}</TableCell>
              <TableCell align="left">{new Date(row.created).toLocaleDateString()}</TableCell>
              <TableCell align="left">{new Date(row.created).toLocaleTimeString()}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10]}
              colSpan={7}
              count={data.customers.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}