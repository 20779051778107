import TopAppBar from "./TopAppBar";
import {Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography} from "@mui/material";
import useGetMessages from "../hooks/queries/useGetMessages";
import ErrorContent from "./ErrorContent";
import LoadingPage from "./LoadingPage";
import {useState} from "react";
import useMessageAction from "../hooks/mutations/useMessageAction";
import {useQueryClient} from "react-query";
import useGetSnoozeValues from "../hooks/queries/useGetSnoozeValues";
import AlertSnackbar from "./AlertSnackbar";

export default function Message() {
  const queryClient = useQueryClient();
  const {data: message, isLoading, isError} = useGetMessages();
  const {data: snooze} = useGetSnoozeValues();
  const [isApiError, setIsApiError] = useState<boolean>();
  const [apiMessage, setApiMessage] = useState('');

  const messageMutation = useMessageAction({
    onSuccess: () => {
      queryClient.invalidateQueries('message');
      setIsApiError(false);
      setApiMessage('Successfully updated message details');
    },
    onError: () => {
      setIsApiError(true);
      setApiMessage('Unable to update message details');
    }
  });
  const [response, setResponse] = useState('');
  const [snoozeMinutes, setSnoozeMinutes] = useState('');

  if (isError) {
    return (
      <>
        <TopAppBar title="Settings"/>
        <ErrorContent />
      </>
    );
  }

  if (isLoading) {
    return <LoadingPage />
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginLeft: '10px',
      marginRight: '10px'
    }}>
      <TopAppBar title="Message" />
      <Typography variant="h6">
        {message.sponsor.name}
      </Typography>
      <Typography variant="body1">
        Hi, {message.toName}. {message.gotd} It’s time for your {message.reminderName}.
        {message.runDays === 1 ? 'Also, please note that this is your last reminder' : ''}
      </Typography>
      {message.state !== "REPLIED" && (
        <Typography variant="body1">{message.setupText}</Typography>
      )}
      <Typography variant="subtitle1">{new Date(message.created).toLocaleString()}</Typography>
      {message.state === "EXPIRED" && (
        <>
          <Typography variant="body1">
            Sorry, this reminder has expired. [Explain what that means to you]
          </Typography>
          <Typography variant="body2">
            {new Date(message.expires).toLocaleString()}
          </Typography>
        </>
      )}
      {message.state === "REPLIED" && (
        <>
          <Typography variant="h6">
            Thanks, we noted your reply!
          </Typography>
          <Typography variant="body1">
            {message.setupText}
          </Typography>
          <Typography variant="body1">
            {message.followupText}
          </Typography>
          <Typography variant="body2">
            {new Date(message.replied).toLocaleString()}
          </Typography>
        </>
      )}
      {["SENT", "SNOOZED", "REMINDED"].indexOf(message.state) > -1 && (
        <>
          <Typography variant="h6">
            I'm Finished
          </Typography>
          <Typography variant="body1">
            When you have finished, type the word "{message.wotd}" below.
          </Typography>
          <TextField
            fullWidth
            value={response}
            onChange={(evt) => setResponse(evt.target.value)}
            label="Your Response"
            type="text"
           />
        </>
      )}
      {["SENT", "SNOOZED", "REMINDED"].indexOf(message.state) > -1 && (
        <>
          {
            message.state === "SNOOZED" ?
              (
                <Button variant="contained" onClick={() => messageMutation.mutate({update: "unsnooze"})}>
                  Cancel Snooze
                </Button>
              )
              :
              (
                <>
                  <FormControl sx={{marginBottom: '10px'}}>
                    <InputLabel id="zz-label">Snooze For</InputLabel>
                    <Select
                      fullWidth
                      labelId="zz-select-label"
                      id="zz-select"
                      value={snoozeMinutes}
                      label="Snooze For"
                      onChange={(evt) => setSnoozeMinutes(evt.target.value)}
                    >
                      {
                        snooze ? snooze.map((s: any) => (
                          <MenuItem value={s.id} key={s.id}>{s.name}</MenuItem>
                        )) : <MenuItem disabled>None</MenuItem>
                      }
                    </Select>
                  </FormControl>
                  <Button variant="contained" onClick={() => messageMutation.mutate({update: "snooze", snoozeMinutes})}>
                    Set Snooze
                  </Button>
                </>
              )
          }
        </>
      )}
      {message.newer && (
        <>
          <Typography variant="body1">
            There is a newer reminder for "{message.reminderName}"... Click "View Latest Reminder" below to see it.
          </Typography>
          <Button href={message.newer.link} fullWidth>
            View Latest Reminder
          </Button>
        </>
      )}
      <AlertSnackbar message={apiMessage} isError={isApiError} onClose={() => setIsApiError(undefined)} />
    </Box>
  )
}