import {useQuery} from "react-query";

export default function useGetContentTypes(sponsor: string | undefined, override: boolean) {
  const key = sponsor ? ['content-types', `${sponsor}`] : ['content-types'];
  const url = sponsor ? `/api/content_types?sponsor=${sponsor}` : '/api/content_types';
  return useQuery(key, async () => {
    // @ts-ignore
    const res = await fetch(url,{
      method: 'GET',
      credentials: 'include',
      headers: new Headers({'Content-Type' : 'application/json'}),
    });

    return res.json();
  }, {retry: false, enabled: sponsor ? !!sponsor : override})
}