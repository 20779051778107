import {useQuery} from "react-query";

export default function useGetMe() {
  return useQuery('me', async () => {
    // @ts-ignore
    const res = await fetch('/api/me',{
      method: 'GET',
      credentials: 'include',
      headers: new Headers({'Content-Type' : 'application/json'}),
    });

    if (!res.ok) {
      return null;
    }
    
    return res.json();
  }, {retry: false})
}