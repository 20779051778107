import { Box, Typography } from '@mui/material'
import { ContentStyle } from "../style/ContentStyle";

export default function Dashboard() {
  return (
    <ContentStyle>
      <Box>
        <Typography variant='h1' sx={{ textAlign: 'center', display: {xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block'}}}>
          RX|Minder Home
        </Typography>
        <Typography variant='h3' sx={{ textAlign: 'center', display: {md: 'none', lg: 'none', xl: 'none'}}}>
          RX|Minder Home
        </Typography>
      </Box>
    </ContentStyle>
  )
}
